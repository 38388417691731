<template>
  <LotList />
</template>

<script>
import LotList from '@/components/list/LotList/VLotList'

export default {
  components: {
    LotList
  }
}
</script>

<style scoped>
</style>
